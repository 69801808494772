<template>
   <v-container grid-list-md>
      <v-layout row wrap>
         <v-flex xs12 md6>
            <h1>Grupos</h1>
         </v-flex>
      </v-layout>
      <v-layout row wrap mt-2>
         <v-flex xs12 md3>
            <v-card elevation="1" >
               <v-simple-table dense>
                  <template v-slot:top>
                     <v-toolbar flat>
                        <div class="flex-grow-1"></div>
                        <v-btn class="mr-2" color="primary" elevation="2" x-small @click="add()">
                           <v-icon small>mdi-plus</v-icon>
                           Añadir
                        </v-btn>
                     </v-toolbar>
                  </template>
                  <template>
                     <thead>
                        <tr>
                           <th>Grupo</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr v-for="item in groups" :key="item._id" v-on:click="onGroupSelected(item)">
                           <td>{{ item.name }}</td>
                           <td class="text-right" style="width:115px">
                              <v-btn
                                 class="ml-1"
                                 outlined
                                 color="primary"
                                 elevation="2"
                                 x-small
                                 @click="edit(item)">
                                 <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                           </td>
                        </tr>
                     </tbody>
                  </template>
               </v-simple-table>
            </v-card>
         </v-flex>
         <!--Editor permisos usuarios -->
         <v-flex xs12 md9>
            <v-card>
               <v-tabs grow color="primary" background-color="#eee" >

                  <v-tab>
                     <v-icon left>
                        mdi-access-point
                     </v-icon>
                     Canales
                  </v-tab>
                  <v-tab>
                     <v-icon left>
                        mdi-account-group
                     </v-icon>
                     Usuarios
                  </v-tab>

                  <!--Tab canales -->
                  <v-tab-item>
                     <v-card class="pa-2">
                        <v-card-title class="group-title">            
                           {{groupSelected.name ? groupSelected.name : "Grupo no seleccionado" }}
                        </v-card-title>
                        <v-container v-show="groupSelected.name">
                           <v-row>
                              <v-col
                                 v-for="item in channelsVM.general"
                                 :key="item.channelName"
                                 style="padding-top:2px;padding-bottom:2px; min-width:200px">
                                 <v-switch
                                    :label="item.channelName"
                                    color="primary"
                                    v-model="selectedChannels"
                                    :value="item.channelName"
                                    hide-details
                                    @change="change"
                                    ></v-switch>
                              </v-col>
                           </v-row>
                           <v-expansion-panels class="mt-5" v-model="activedPanels"              
                              multiple>
                              <v-expansion-panel v-for="group in channelsVM.grouped" :key="group.name">
                                 <v-expansion-panel-header>{{group.name}}</v-expansion-panel-header>
                                 <v-expansion-panel-content>
                                    <v-row class="ma-1">
                                       <v-col
                                          v-for="item in group.channels"
                                          :key="item.channelName"
                                          style="padding-top:2px;padding-bottom:2px; min-width:200px">
                                          <v-switch
                                             :label="item.description? item.description : item.channelName"
                                             color="primary"
                                             v-model="selectedChannels"
                                             :value="item.channelName"
                                             hide-details
                                             @change="change"
                                             ></v-switch>
                                       </v-col>
                                    </v-row>
                                 </v-expansion-panel-content>
                              </v-expansion-panel>
                           </v-expansion-panels>
                        </v-container>
                     </v-card>
                  </v-tab-item>

                  <!--Tab usuarios -->
                  <v-tab-item>
                     <v-card class="pa-2">
                        <v-card-title class="group-title">            
                           {{groupSelected.name ? groupSelected.name : "Grupo no seleccionado" }}
                        </v-card-title>
                        <v-layout row wrap mt-2 v-show="groupSelected.name">
                           <v-flex xs6 md6 >
                              <v-card elevation="0">
                                 <v-card-title>Usuarios del grupo
                                 <div class="flex-grow-1"></div>
                                 <input type="text" v-model="searchAdded" placeholder="Buscar..." class="txtSearch"/></v-card-title>
                                 <v-divider class="mx-4"></v-divider>

                                 <v-card-text>
                                    <group-user 
                                        v-for="item in addedUsers"
                                       :add="false"
                                       :groupId="groupSelected._id"
                                       :key="item.username"
                                       :user="item"></group-user>
                                 </v-card-text>
                              </v-card>
                           </v-flex>
                           <v-flex xs6 md6>
                              <v-card elevation="0">
                                 <v-card-title>Usuarios
                                 <div class="flex-grow-1"></div>
                                 <input type="text" v-model="searchPending" placeholder="Buscar..." class="txtSearch"/></v-card-title>
                                 <v-divider class="mx-4"></v-divider>
                                 <v-card-text>
                                    <group-user  
                                       v-for="item in pendingUsers"
                                       :add="true"
                                       :groupId="groupSelected._id"
                                       :key="item._id"
                                       :user="item"></group-user>
                                 </v-card-text>
                              </v-card>
                           </v-flex>
                        </v-layout>
                     </v-card>
                  </v-tab-item>
               </v-tabs>
            </v-card>
         </v-flex>
      </v-layout>

      <!--Dialog delete group-->
      <vue-confirm-dialog></vue-confirm-dialog>

      <!--Dialog group editor-->
      <v-dialog v-model="editorDialog" max-width="500">
         <v-card>
            <v-card-title class="headline grey lighten-2">
               Grupo 
            </v-card-title>
            <v-form ref="form" lazy-validation @keyup.native.enter="submit">
               <v-container>
                  <v-card-text>
                     <v-container class="pb-0" fluid>
                        <v-row>
                           <v-col cols="12">
                              <v-text-field
                                 label="Nombre"
                                 v-model="groupItem.name"
                                 filled
                                 hide-details="auto"
                                 prepend-icon="mdi-account"
                                 :rules="requiredRule"
                                 ></v-text-field>
                           </v-col>
                        </v-row>
                     </v-container>
                  </v-card-text>
               </v-container>
            </v-form>
            <v-progress-linear :indeterminate="loading" color="primary" class="mb-0">
            </v-progress-linear>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="primary" text @click="save"> Guardar </v-btn>
               <v-btn color="primary" text @click="editorDialog = false">
                  Cancelar
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-container>
</template>

<script>
import Vue from "vue";
import VueConfirmDialog from "vue-confirm-dialog";
import GroupUser from '../components/GroupUser.vue';

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

export default {
  name: "Groups",
  components: {GroupUser},
  data: () => ({
    searchAdded:"",
    searchPending:"",
    groupSelected: {},
    requiredRule: [v => (v != null && v != "" ) || "Campo obligatorio"],
    editorDialog: false,
    editMode: false,
    groupItem: {},
    selectedChannels: [],
    channelsVM: { general: [], grouped: [], expaded:[]},
    activedPanels: [],
    //addedUsers: [],
    //pendingUsers: []
  }),
  created() {
    this.$store.dispatch("fetchGroups");
    this.$store.dispatch("fetchChannels");
    this.$store.dispatch("fetchUsers");
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    groups() {
      return this.$store.getters.getGroups;
    },
    channels() {
      return this.$store.getters.getChannels;
    },
    users() {
      return this.$store.getters.getUsers;
    },
    addedUsers() {

      if (!this.users)
         return [];

      return this.users.filter(u => u.groups && u.groups.indexOf(this.groupSelected._id) > -1 && u.username.toLowerCase().includes(this.searchAdded.toLowerCase()));
    },
    pendingUsers() {
      
      if (!this.users)
         return [];

      return this.users.filter(u => (!u.groups) || u.groups.indexOf(this.groupSelected._id) == -1 && u.username.toLowerCase().includes(this.searchPending.toLowerCase()));
    },
  },
  methods: {

    onGroupSelected: function(item) {
      this.searchAdded = "",
      this.searchPending = "",
      this.groupSelected = item;
      this.selectedChannels = item.channels;
      this.channelsVM= { general: [], grouped: [], expaded:[]};

      // obtención del objeto de vista para pintar los grupos de paneles con sus canales
      for (let i = 0; i < this.channels.length; i++) {  
         var c = this.channels[i];    
         if (c.group == null) this.channelsVM.general.push(c);
         else {
            var group = this.channelsVM.grouped.filter(g => g.name == c.group);
            if (group.length > 0) //Si el grupo ya existe
               group[0].channels.push(c);
            else {
               //añado nuevo panel con el canal
               let g = { name: c.group, channels: new Array(c)}
               this.channelsVM.grouped.push(g);
               group.push(g)              
            }
      
         }
      }
    
    },
    add: function() {
      this.groupItem = {};
      this.editorDialog = true;
      this.editMode = false;
    },
    edit: function(item) {
      this.groupItem = item;
      this.editorDialog = true;
      this.editMode = true;
    },
    save: function() {
      var form = this.$refs.form;
      if (form.validate()) {
        console.log(this.groupItem._id);
        this.$store
          .dispatch((!this.groupItem._id?"createGroup":"updateGroup"), this.groupItem)
          .then(() => {
            this.$store.dispatch("fetchGroups");
            this.editorDialog = false;
          })
          .catch(() => {
            this.editorDialog = false;
            alert("No se ha podido guardar el grupo.");
          });
      }
    },
    change(values) {      
      this.groupSelected.channels = values;
      this.$store.dispatch("updateGroup", this.groupSelected);                
    },
  },
};
</script>

<style scoped>

.txtSearch
{
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 3px;
    width: 120px;
    height: 30px;
    font-size: 14px;
}

.v-card__title
{
  padding: 10px;
}

.group-title
{
   color: #0072ce !important;
   padding: 15px 0px 0 10px;
}

</style>
